import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { COOKIE_TOKEN_NAME } from '@/constant';
import { cookie } from '@/utils/cookie';
import { getUserInfoData } from '@/apis/app';
import { RootState } from '@/store';

// 路由守卫
const BeforeRouter = ({ element }: { element: JSX.Element }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { loginState } = useSelector((state: RootState) => state.app);
  const token = cookie.get(COOKIE_TOKEN_NAME);

  // admin跳转入口，走自己的登录逻辑
  const whiteList = ['/otherEntry'];
  const redirectHomeRouters = ['/login', '/', ''];
  const permissionPage = ['/adminStatistics'];

  const isEmpty = (val) => {
    return val === 'null' || val === undefined || val === 'undefined' || val === '' || !val;
  };

  const updateUserPermission = async () => {
    const res = await getUserInfoData();
    return res.data.isPermission;
  };

  const onRouterBefore = () => {
    // debugger;
    if (loginState || !isEmpty(token)) {
      // 已登录
      if (redirectHomeRouters.includes(pathname)) {
        return '/salework-station/dashboard';
      }
    } else if (!whiteList.includes(pathname)) {
      // 未登录
      return '/login';
    }
    if (permissionPage.includes(pathname)) {
      updateUserPermission().then((res) => {
        if (!res) {
          window.location.href = '/404';
        }
      });
    }
    // 保持当前路由
    return '';
  };

  useEffect(() => {
    // debugger;

    const nextPath = onRouterBefore() || '';
    if (nextPath && nextPath !== pathname) {
      navigate(nextPath, { replace: true });
    }
  }, [pathname]);

  return element;
};

export default BeforeRouter;
