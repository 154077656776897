import la from '../LazyComponent';

const salesNote = la(() => import('@/pages/SalesNote'));

const SalesNoteRoutes = [
  {
    path: '/sales-note',
    element: salesNote,
    meta: {
      key: 'salesNote',
    },
  },
];

export default SalesNoteRoutes;
